<template>
  <div>
    <div class="box">
      <el-page-header
        @back="$router.go(-1)"
        :content="content"
      ></el-page-header>
    </div>
    <el-card class="content mt20">
      <el-form
        label-position="left"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('subject.title')" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
        <el-form-item :label="$t('subject.order')" prop="order">
          <el-input
            @input="
              (res) => {
                this.ruleForm.order = +res;
              }
            "
            @mousewheel.native.prevent
            @DOMMouseScroll.native.prevent
            type="number"
            placeholder="정렬순이 높은 건이 우선 노출됩니다."
            v-model="ruleForm.order"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('subject.position_id')" prop="position_id">
          <el-select
            v-model="ruleForm.position_id"
            :placeholder="$t('please_select')"
          >
            <el-option
              v-for="item in positionOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('subject.status')" prop="status">
          <el-radio-group size="medium" v-model="ruleForm.status">
              <el-radio :label="0">{{status(0)}}</el-radio>
              <el-radio :label="1">{{status(1)}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('subject.is_hot')" prop="is_hot">
          <el-radio-group size="medium" v-model="ruleForm.is_hot">
              <el-radio :label="0">{{is_hot(0)}}</el-radio>
              <el-radio :label="1">{{is_hot(1)}}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('subject.time')" prop="time">
          <el-input v-model="ruleForm.time"></el-input>
        </el-form-item>
        <el-form-item :label="$t('subject.price')" prop="price">
          <el-input
            @input="
              (res) => {
                this.ruleForm.price = +res;
              }
            "
            @mousewheel.native.prevent
            @DOMMouseScroll.native.prevent
            type="number"
            :placeholder="$t('subject.price')"
            v-model="ruleForm.price"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('subject.cover')" prop="cover">
          <upload-img
            :limitMax="1"
            :file-list="ruleForm.cover"
            @changeImgList="changeImgList"
          ></upload-img>
        </el-form-item>
        <el-form-item v-if="load" :label="$t('subject.content_desc')" prop="content_desc">
            <SummerNote :id="'summernote' + new Date().getTime()" v-model="ruleForm.content_desc" />
        </el-form-item>
        <div class="fs26">{{ $t("subject.content") }}</div>
        <div
          class="mt20 subject-options"
          v-for="(item, index) in ruleForm.content"
          :key="index"
        >
          <div style="flex: 1" class="mr20">
            <el-form-item
              :label="$t('subject.con_name')"
              :prop="`content[${index}].name`"
            >
              <el-input v-model="ruleForm.content[index].name"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('subject.con_title')"
              :prop="`content[${index}].title`"
            >
              <el-input v-model="ruleForm.content[index].title"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('subject.con_desc')"
              :prop="`content[${index}].desc`"
            >
              <el-input v-model="ruleForm.content[index].desc"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('subject.con_service')"
              :prop="`content[${index}].service`"
            >
              <el-input v-model="ruleForm.content[index].service"></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('subject.con_price')"
              :prop="`content[${index}].price`"
            >
              <el-input
                @input="changeInput(index)"
                @mousewheel.native.prevent
                @DOMMouseScroll.native.prevent
                type="number"
                v-model="ruleForm.content[index].price"
              ></el-input>
            </el-form-item>
            <el-form-item
              :label="$t('subject.con_days')"
              :prop="`content[${index}].days`"
            >
              <el-input v-model="ruleForm.content[index].days"></el-input>
            </el-form-item>
          </div>
          <div>
            <el-button type="primary" @click="reduce(index)">
              <i class="el-icon-minus"></i>
            </el-button>
            <el-button type="primary" @click="add()">
              <i class="el-icon-plus"></i>
            </el-button>
          </div>
        </div>
      </el-form>
      <el-button class="btn" type="primary" @click="submitForm('ruleForm')">{{
        $t("btnTip.submit")
      }}</el-button>
    </el-card>
  </div>
</template>
<script>
import SummerNote from '@/components/summernote';
import {
  createSubject,
  examTypeList,
  editSubject,
  subjectDetail,
} from "@/api/index";
import uploadImg from "@/components/uploadImg";
export default {
  name: "editSubject",
  components: { uploadImg, SummerNote },
  data() {
    return {
      ruleForm: {
        title: "",
        position_id: "",
        cover:"",
        order: 0,
        status: 0,
        is_hot: 0,
        price: 0,
        time: "",
        content_desc:"",
        content: [
          {
            title: "",
            name: "",
            desc: "",
            service: "",
            price: 0,
            days:0
          },
        ],
      },
      positionOptions: [],
      base:{
        title: [
          {
            required: true,
            message: this.$t("subject").title,
            trigger: "blur",
          },
        ],
        position_id: [
          {
            required: true,
            message: this.$t("subject").position_id,
            trigger: "blur",
          },
        ],
        cover: [
          {
            required: true,
            message: this.$t("subject").cover,
            trigger: "blur",
          },
        ],
         content_desc: [
          {
            required: true,
            message: this.$t("subject").content_desc,
            trigger: "blur",
          },
        ],
        order: [
          {
            required: true,
            message: this.$t("subject").order,
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("subject").status,
            trigger: "blur",
          },
        ],
        is_hot: [
          {
            required: true,
            message: this.$t("subject").is_hot,
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: this.$t("subject").price,
            trigger: "blur",
          },
        ],
        time: [
          {
            required: true,
            message: this.$t("subject").time,
            trigger: "blur",
          },
        ],
      },
      rules: {
        
      },
      load:false
    };
  },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    },
    content() {
      return this.id === undefined
        ? this.$t("btnTip").add
        : this.$t("btnTip").edit;
    },
  },
  methods: {
    status(status) {
      switch (status) {
        case 0:
          return this.$t("subject.status0");
        case 1:
          return this.$t("subject.status1");
      }
    },
    is_hot(is_hot) {
      switch (is_hot) {
        case 0:
          return this.$t("subject.is_hot0");
        case 1:
          return this.$t("subject.is_hot1");
      }
    },
    changeRules() {
      let obj = {};
      this.ruleForm.content.forEach((item, index) => {
        obj[`content[${index}].title`] = [
          {
            required: true,
            message: this.$t("subject").con_title,
            trigger: "blur",
          },
        ];
        obj[`content[${index}].name`] = [
          {
            required: true,
            message: this.$t("subject").con_name,
            trigger: "blur",
          },
        ];
        obj[`content[${index}].desc`] = [
          {
            required: true,
            message: this.$t("subject").con_desc,
            trigger: "blur",
          },
        ];
        obj[`content[${index}].price`] = [
          {
            required: true,
            message: this.$t("subject").con_price,
            trigger: "blur",
          },
        ];
        obj[`content[${index}].service`] = [
          {
            required: true,
            message: this.$t("subject").con_service,
            trigger: "blur",
          },
        ];
        obj[`content[${index}].days`] = [
          {
            required: true,
            message: this.$t("subject").con_days,
            trigger: "blur",
          },
        ];
      });
      this.rules = { ...this.base, ...obj };
    },
    reduce(index) {
      if (this.ruleForm.content.length === 1) return;
      this.ruleForm.content.splice(index, 1);
      this.changeRules();
    },
    add() {
      this.ruleForm.content.push({
        title: "",
        name: "",
        desc: "",
        service: "",
        price: 0,
        days: 0,
      });
      this.changeRules()
    },
    changeInput(index){
      this.ruleForm.content[index].price = +this.ruleForm.content[index].price
    },
    changeImgList(arr) {
      if (arr.length === 0) {
        this.ruleForm.cover = "";
      } else {
        this.ruleForm.cover = arr[0];
      }
    },
    async submitForm(formName) {
      await this.$refs[formName].validate();
      if (this.id === undefined) {
        await createSubject(this.ruleForm);
      } else {
        await editSubject({ id: this.id, ...this.ruleForm });
      }
      this.$router.go(-1);
    },
    async get_info() {
      const information = await subjectDetail(this.id);
      this.ruleForm = { ...information };
      this.load = true
      this.changeRules()
    },
  },
  async created() {
    this.changeRules();
    let res = await examTypeList({
      page: 1,
      page_size: 50,
    });
    this.positionOptions = res.results.map((item) => {
      return {
        value: item.id,
        label: item.title,
      };
    });
    if (this.id === undefined){
      this.load = true
      return
    }
    this.get_info();
  },
};
</script>
<style lang="scss" scoped>
.subject-options {
  display: flex;
  justify-content: space-between;
}
</style>